<template>
    <div class="container">
        <div class="main" :class="{ flod }">
            <img src="@/assets/index.png" alt="" :style="{ width: flod ? '80%' : '50%' }" />
            <div class="content" :class="{ flod }">
                <a-button size="large" style="width:256px;margin-top:20px" type="primary" @click="login">登 录</a-button>
                <a-checkbox v-model:checked="auto_login">在企业微信内默认自动登录</a-checkbox>
            </div>
            <div class="bottom">QuarkMed ERP</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            flod: document.body.clientWidth >= 640 ? false : true,
            auto_login: this.$store.state.local_setting.auto_login,
        };
    },
    methods: {
        login() {
            if (this.auto_login) {
                this.$store.commit('local_setting', { auto_login: this.auto_login });
            }
            this.$router.push('/login?force=1');
        },
    },
    created() {
        if (this.auto_login && window.navigator.userAgent.toLowerCase().indexOf('wxwork') != -1) {
            this.$router.push('/login');
            this.$message.success('已自动进行登录');
        } else {
            this.auto_login = true;
        }
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.flod = document.body.clientWidth >= 640 ? false : true;
            })();
        };
    },
};
</script>

<style scoped>
.container {
    background: #448cff;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main {
    width: 80%;
    height: 80vh;
    background: #f7f7f7;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
}
.main.flod {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    margin: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.content {
    width: 50%;
    margin-top: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.content.flod {
    width: 100%;
    margin-top: 0px;
}
.bottom {
    text-align: center;
    font-size: 1.2em;
    line-height: 2.4em;
    width: 100%;
}
.flod {
    width: 100%;
    margin-top: 0px;
}
</style>
